import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

import FHA from "../images/7.0 Mortgage Calculators/FHA.svg";
import homeAffordability from "../images/7.0 Mortgage Calculators/home affordability.svg";
import mortgagePayment from "../images/7.0 Mortgage Calculators/mortgage payment.svg";
import VA from "../images/7.0 Mortgage Calculators/VA.svg";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Mortgage Calculators | Right Start | Mortgage Lender"
        description="Use our free mortgage calculators to help you align your budget with your home buying goals. Then contact us when you're ready to get started."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Mortgage Calculators</h1>
              <p>
                Use our free mortgage calculators to help you align your budget
                with your home buying goals.
              </p>
              <ButtonSolid
                modal="modal-contact"
                text="Contact Us"
                altStyle={2}
              />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/7.0 Mortgage Calculators/1.0 mortgage - calc - hero.png"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-8 md:grid-cols-2 md:gap-12">
            <Link
              to="/mortgage-calculator/"
              className="flex w-full flex-wrap items-center rounded-md border border-gray-200 p-4 font-normal text-typography-body no-underline hover:border-primary-500 hover:text-typography-body md:flex-nowrap md:space-x-6"
            >
              <div className="mb-4 w-full md:mb-0 md:w-auto">
                <img
                  src={mortgagePayment}
                  alt="Mortgage Payment Calculator"
                  loading="lazy"
                  width={96}
                />
              </div>
              <div className="flex-1">
                <h2 className="heading-six mb-2">
                  Mortgage Payment Calculator
                </h2>
                <p className="mb-0">
                  Estimate how much your monthly mortgage payment would be for a
                  house you’re considering buying.
                </p>
              </div>
            </Link>

            <Link
              to="/va-loan-calculator/"
              className="flex w-full flex-wrap items-center rounded-md border border-gray-200 p-4 font-normal text-typography-body no-underline hover:border-primary-500 hover:text-typography-body md:flex-nowrap md:space-x-6"
            >
              <div className="mb-4 w-full md:mb-0 md:w-auto">
                <img
                  src={VA}
                  alt="VA Loan Calculator"
                  loading="lazy"
                  width={96}
                />
              </div>
              <div className="flex-1">
                <h2 className="heading-six mb-2">VA Loan Calculator</h2>
                <p className="mb-0">
                  Find out how much your estimated monthly payment would be if
                  you qualify for a VA loan.
                </p>
              </div>
            </Link>

            <Link
              to="/fha-loan-calculator/"
              className="flex w-full flex-wrap items-center rounded-md border border-gray-200 p-4 font-normal text-typography-body no-underline hover:border-primary-500 hover:text-typography-body md:flex-nowrap md:space-x-6"
            >
              <div className="mb-4 w-full md:mb-0 md:w-auto">
                <img
                  src={FHA}
                  alt="FHA Loan Calculator"
                  loading="lazy"
                  width={96}
                />
              </div>
              <div className="flex-1">
                <h2 className="heading-six mb-2">FHA Loan Calculator</h2>
                <p className="mb-0">
                  Find out how much your estimated monthly payment would be if
                  you qualify for an FHA loan.
                </p>
              </div>
            </Link>

            <Link
              to="/home-affordability-calculator/"
              className="flex w-full flex-wrap items-center rounded-md border border-gray-200 p-4 font-normal text-typography-body no-underline hover:border-primary-500 hover:text-typography-body md:flex-nowrap md:space-x-6"
            >
              <div className="mb-4 w-full md:mb-0 md:w-auto">
                <img
                  src={homeAffordability}
                  alt="Home Affordability Calculator"
                  loading="lazy"
                  width={96}
                />
              </div>
              <div className="flex-1">
                <h2 className="heading-six mb-2">
                  Home Affordability Calculator
                </h2>
                <p className="mb-0">
                  Find out how much home you can afford based on your family’s
                  budget.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Mortgage Calculators.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Mortgage Calculators.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
